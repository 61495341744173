body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiBackdrop-root {
  background-color: rgba(0,69,111,.75)!important; 
}

@keyframes orbit {
	from { transform: rotate(0deg) translateX(150px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(150px) rotate(-360deg); }
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes float {
	0% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	12% {
		transform: translatex(-20px);
	}
	25% {
		transform: translatex(0px);
	}
	37% {
		transform: translatex(-20px);
		transform: translatey(20px);
	}
	50% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	62% {
		transform: translatex(20px);
	}
	75% {
		transform: translatex(0px);
	}
	87% {
		transform: translatex(-20px);
		transform: translatey(-20px);
	}
	100% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	12% {
		transform: translatex(20px);
	}
	25% {
		transform: translatex(0px);
	}
	37% {
		transform: translatex(-20px);
		transform: translatey(20px);
	}
	50% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	62% {
		transform: translatex(-20px);
	}
	75% {
		transform: translatex(0px);
	}
	87% {
		transform: translatex(-20px);
		transform: translatey(-20px);
	}
	100% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
}

@keyframes float3 {
	0% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	12% {
		transform: translatex(-20px);
		transform: translatey(20px);
	}
	25% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	37% {
		transform: translatex(20px);
	}
	50% {
		transform: translatex(0px);
	}
	62% {
		transform: translatex(-20px);
		transform: translatey(-20px);
	}
	75% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	87% {
		transform: translatex(-20px);
	}
	100% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
}

@keyframes float4 {
	0% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	12% {
		transform: translatex(20px);
		transform: translatey(-20px);
	}
	25% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	37% {
		transform: translatex(-20px);
	}
	50% {
		transform: translatex(0px);
	}
	62% {
		transform: translatex(20px);
		transform: translatey(20px);
	}
	75% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
	87% {
		transform: translatex(20px);
	}
	100% {
		transform: translatex(0px);
		transform: translatey(0px);
	}
}

@keyframes oldfloat {
	0% {
		transform: translatex(0px);
	}
	25% {
		transform: translatex(-40px);
	}
	50% {
		transform: translatex(0px);
	}
  	51% {
    	-webkit-transform: translateY(-45px);
        transform: translateY(-45px);
    	-webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    	opacity: 1;
  	}
  	62% {
    	opacity: 1;
  	}
  	70% {
    	-webkit-transform: translateY(-24px);
        transform: translateY(-24px);
    	-webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
  	}
  	82% {
    	-webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    	-webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
  	}
  	91% {
    	-webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    	-webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
  	}
  	96% {
    	-webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    	-webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
  	}
  	62%,
  	77%,
  	87%,
  	93% {
    	-webkit-transform: translateY(0px);
        transform: translateY(0px);
    	-webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
  	}
  	100% {
    	-webkit-transform: translateY(0px);
        transform: translateY(0px);
    	-webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    	opacity: 1;
  	}
}
